<template>
  <v-card>
    <v-card-title class="headline">{{ title }}</v-card-title>

    <v-card-text style="max-height: 80vh; overflow-y: scroll">
      <p v-if="text" v-html="text" />
      <v-textarea
        v-if="prompt"
        v-model="input"
        v-bind="$attrs"
        :type="type"
        ref="input"
        @keyup.native.enter="confirm"
      />
      <component v-if="component" :is="component" v-bind="componentProps" />
    </v-card-text>

    <v-card-actions class="elevation-8">
      <v-spacer />
      <v-btn v-if="cancel" text color="primary" @click="close()">{{ cancel }}</v-btn>
      <v-btn text color="primary" :loading="processing" @click="confirm">{{ ok }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    prompt: { type: Boolean, default: false },
    component: { type: Function, default: () => null },
    componentProps: { type: Object, default() {} },
    type: { type: String, default: 'text' },
    inputInit: { type: String, default: '' },
    cancel: { type: String, default: '' },
    ok: { type: String, default: '' },
    onSave: { type: Function, default: () => null },
  },
  data() {
    return {
      processing: false,
      input: '',
    }
  },
  created() {
    this.input = this.inputInit
  },
  mounted() {
    if (this.prompt) this.$refs.input.focus()
  },
  methods: {
    ...mapActions('dialog', ['close']),
    async confirm() {
      this.processing = true
      await this.onSave(this.input)
      this.processing = false
      this.close(this.input || true)
    },
  },
}
</script>
